<template>
	<div :style="bg">
		<Header :title="$t('setting.address')"></Header>
		
		<div class="column-center-content main-content">
			<label style="color: #FF0000; font-size: 18px;">{{$t('setting.addressTip')}}</label>
			<label style="margin-top: 20px; font-size: 18px;" v-text="walletAddress"></label>
			<router-link to="/addressAdd" v-if="walletAddress === '' || walletAddress === null" class="btn-content">
				<v-btn rounded block style="background-color: #009245; color: white; height: 40px;">
					{{$t('setting.addAddress')}}
				</v-btn>
			</router-link>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import Header from '../../../components/NavHeader.vue'
	
	export default {
		name: 'AddressList',
		components: {
			Header
		},
		data() { 
			return {
				bg:{
					height: "100vh",
					width: "100%",
					backgroundImage: "url(" + require("../../../assets/login/new-bg.png") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto"
				},
				walletAddress: JSON.parse(localStorage.getItem('walletInfo')).withdrawAddress
			}
		}
	}
</script>

<style>
</style>
